// No need to match package structure, to force compile time switch of dependencies
class StagingFlavor : Flavor {
    override val clientName: String = "Linx-Staging"
    override val clientUrl: String = "linx-staging.enrollandpay.com"
    override val serverHttps: Boolean = true
    override val serverUrl: String = "linx-appserver-staging.azurewebsites.net"
    override val serverPort: Int? = null
    override val databaseConnectionString: String =
        "jdbc:sqlserver://enrollandpay.database.windows.net:1433;database=EnrollAndPayV2-Staging;encrypt=true;trustServerCertificate=false;hostNameInCertificate=*.database.windows.net;loginTimeout=30;"
    override val serverBusConnectionString: String = "Endpoint=sb://enrollandpaystaging.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=pBFyuwKR3kvxLscp9grRBp/6WfIlyJnYgJWq9Lr5r/g="
    override val enpTerminalServerHost: String = "https://enpterminalapi-staging.azurewebsites.net/"
    override val token: String = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnZpcm9ubWVudCI6InN0YWdpbmciLCJuYW1lIjoiZW5yb2xsYW5kcGF5In0.wKXgftCXW0180GWWB6eBlbCeV57bggh1Xzg2KWID06w"
}